module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-XXXXXXXX","head":true},
    },{
      plugin: require('../node_modules/gatsby-emotion-dark-mode/gatsby-browser.js'),
      options: {"plugins":[],"light":{"color":{"black100":"#0F1010","black60":"#2C2D2E","black40":"#3C3D40","gray80":"#808388","gray60":"#989BA0","gray40":"#C0C5C9","gray20":"#EEEFF1","gray10":"#F7F8FA","white100":"#FFFFFF"}},"dark":{"color":{"black100":"#e6e6e6","black60":"#F7F8FA","black40":"#EEEFF1","gray80":"#b9bbc5","gray60":"#8e8f97","gray40":"#626368","gray20":"#3C3D40","gray10":"#2C2D2E","white100":"#232326"}}},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":720,"linkImagesToOriginal":false,"backgroundColor":"transparent","showCaptions":false,"markdownCaptions":false,"quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"hyun-seok.com","short_name":"hyun-seok.com","description":"개발자 석이","start_url":"/","lang":"en","display":"standalone","icon":"static/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"957b9aadf2978a61542fda6f02e1f6e5"},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
